module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-162452662-1"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"wadeintoadventure","accessToken":"MC5YbDdLRnhNQUFDSUFKSzIz.77-977-977-9Lu-_ve-_vXI1C--_ve-_ve-_ve-_vWYn77-9KR3vv73vv70WDe-_vSI1Yzvvv73vv71EEO-_vQ","path":"/preview","previews":true,"pages":[{"type":"Post","match":"/posts/:uid","path":"/posts-preview","component":"/opt/build/repo/src/components/Post/index.js"},{"type":"Page","match":"/:uid","path":"/pages","component":"/opt/build/repo/src/components/Page/index.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Švietimo nuotykiai","short_name":"Švietimo nuotykiai","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
