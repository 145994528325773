module.exports = ({
  uid,
  type,
}) => {
  if (type === 'post') {
    return `/posts/${uid}`
  }

  if (type === 'page') {
    return `/${uid}`
  }

  return '/'
}
